<script>
export default {
  name: "Redirect",
  created() {
    switch (this.$route.params.where) {
      case 'transfer':
        this.$router.push({name: 'transport-transfer', params: {key: this.$route.params.key, mode: 'transfer'}})
        break
      case 'pickup':
        this.$router.push({name: 'transport-pickUp', params: {key: this.$route.params.key, mode: 'pickUp'}})
        break
    }
  },
  template: '<Redirect />'
}
</script>
