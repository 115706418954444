<!-- Modal pro detail objednávky -->
<template>
  <div class="order-item is-full-screen ">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Order <span>{{order.showId}}</span></p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <!---->
        <form action="#" @submit.prevent="orderDelivered">
          <div class="hand-over">
            <div v-if="mode == 'recipient'" class="order-info recipient">
              <p>
                Já, <span>{{order.address.firstname}} {{order.address.lastname}}</span>, přebírám zásilku <span>{{order.showId}}</span>
                <span v-if="order.countItems > 1">, která obsahuje {{order.countItems}} kusy zboží</span>.
              </p>
            </div>
            <div v-else class="else">
              <div class="columns-my">
                <div class="name is-half">
                  <b-field label="Jméno a příjmení přebírajícího">
                    <b-input
                        placeholder="Jméno a příjmení přebírajícího"
                        type="text"
                        v-model="name"
                        required>
                    </b-input>
                  </b-field>
                </div>
                <div class="relationship is-half">
                  <b-field label="Vztah k osobě">
                    <b-select
                        placeholder="Vyberte"
                        v-model="relationship"
                        required>
                      <option value="Manžel/Manželka">Manžel/Manželka</option>
                      <option value="Druh/Družka">Druh/Družka</option>
                      <option value="Tchán/Tchýně">Tchán/Tchýně</option>
                      <option value="Dítě">Dítě</option>
                      <option value="Sourozenec">Sourozenec</option>
                      <option value="Spolubydlící">Spolubydlící</option>
                      <option value="Spolubydlící">Recepce</option>
                      <option value="Jiný">Jiný</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="note">
              <div class="name is-half">
                <b-field label="Poznámka">
                  <b-input
                      placeholder="Poznámka"
                      type="text"
                      v-model="note"
                      >
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="signature">
              <FreehandDrawSVG
                  ref="draw"
                  v-bind:title="drawProps.title"
                  v-bind:colors="drawProps.colors"
                  v-bind:bgColors="drawProps.bgColors"
                  v-bind:lineColor = "drawProps.lineColor"
                  v-bind:bgColor = "drawProps.bgColor"
                  v-on:changeLineColor = "changeLineColor"
                  v-on:changeBgColor = "changeBgColor"
                  v-on:drawSubmit="saveDrawing"
              >
              </FreehandDrawSVG>
            </div>
              <div class="buttons">
                <b-button type="is-primary"
                          tag="input"
                          native-type="submit"
                          value="Potvrdit">
                </b-button>
              </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>
<script>
import FreehandDrawSVG from "../../draw/FreehandDrawSVG";

export default {
  name: "OrderHandOver",
  components: {
    FreehandDrawSVG,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {
      //
      drawProps: {
        title: "Freehand SVG Draw",
        colors: ['#EAEAEA','#292929', '#00A0FF', '#FF29AA', '#FFE500', '#FF3030', '#12EB0A'],
        bgColors: ['#EAEAEA','#292929', '#00A0FF', '#FF29AA', '#FFE500', '#FF3030', '#12EB0A'],
        lineColor: "#292929",
        bgColor: "#EAEAEA"
      },
      //
      name: null,
      relationship: null,
      signature: null,
      note: null,
      isDraw: false,
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    // draw metody
    changeLineColor: function(color){
      this.drawProps.lineColor = color;
    },
    changeBgColor: function(color){
      this.drawProps.bgColor = color;
    },
    saveDrawing: function(drawSvg){
      console.log (drawSvg)
    },
    getSignature() {
      return this.$refs.draw.svgDataURL()
    },
    // odeslání objednávky
    orderDelivered() {
      this.signature = this.getSignature()
      console.log(this.signature)
      if (this.mode == 'recipient') {
        this.name = this.order.address.firstname + ' ' + this.order.address.lastname
        this.relationship = 'Adresát'
      }
      this.$store.dispatch('setOrderHandOver', {
        id: this.order.id,
        key: this.order.key,
        itemId: this.order.inhouseItemId,
        data: {
          mode: this.mode,
          name: this.name,
          relationship: this.relationship,
          signature: this.signature,
          note: this.note
        }
      })
          .then(response => {
            console.log(response)
            this.$emit('close') // todo jak zavřít ten modal??
            this.$parent.$emit('close') // todo jak zavřít ten modal??
            this.$parent.$parent.$emit('close') // todo jak zavřít ten modal??
            this.$router.push({name: 'redirect', params: {where: 'transfer', key: this.order.key}})
          })
          .catch(error => {
            console.table(error)
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            this.errorApiCode = error
            this.errorApiMessage = error
            this.successMessage = ''
          })
      this.loading = false
    },
    resetDraw() {
      this.isDraw = false;
    },
    setDraw() {
      this.isDraw = true;
      console.log(this.isDraw)
    },

  }
}
</script>

<style lang="scss">

</style>