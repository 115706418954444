// import MainPage from "./components/MainPage";
import Login from "./components/auth/Login";
import ReadTransport from "./components/transport/ReadTransport";
import PickUpTransport from "./components/transport/PickUpTransport";
import Redirect from "./components/transport/Redirect";
import Logout from "./components/auth/Logout";
import FinishedTransport from "./components/transport/FinishedTransport";
import CancelTransport from "@/components/transport/CancelTransport";

const routes = [
    {   // načtení závozu (qr)
        path: '/',
        name: 'home',
        component: ReadTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // načtení závozu (qr)
        path: '/transport/read',
        name: 'transport-read',
        component: ReadTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // nakládka
        path: '/transport/pickUp/:mode/',
        name: 'transport-pickUp',
        component: PickUpTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // předávky
        path: '/transport/transfer/:mode/',
        name: 'transport-transfer',
        component: PickUpTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // přerušit závoz
        path: '/transport/cancel/',
        name: 'transport-cancel',
        component: CancelTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // konec
        path: '/transport/finished',
        name: 'transport-finish',
        component: FinishedTransport,
        meta: {
            requiresAuth: true,
        }
    },
    {   // "přesměrování"
        path: '/redirect/:key/:where',
        name: 'redirect',
        component: Redirect
    },
    {   // login
        path: '/login',
        name: 'login',
        component: Login
    },
    {   // login
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    // {
    //     path: '/old',
    //     name: 'homeOld',
    //     component: MainPage
    // },
    // {
    //     path: '/transport/handOver/:key-:order-:mode',
    //     name: 'transport-handOver',
    //     component: PickUpTransport
    // },
]

export default routes;