<!-- Modal pro detail objednávky -->

<template>
  <div class="order-item is-full-screen ">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Order <span>{{order.showId}}</span></p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <div v-if="order.countItems > 1 || order.cod.required" class="order-info">
          <div v-if="order.countItems > 1">
            Objednávka obsahuje více položek
            <span>{{order.countItems}}</span>
          </div>
          <div v-if="order.cod.required">
            Dobírka
            <span>{{order.cod.price}}</span>
          </div>
        </div>
        <order-item-hand-over :order="order" :index="order.id" :mode="mode"></order-item-hand-over>
        <div v-if="mode === 'pickUp'" class="buttons">
          <b-button
              tag="a"
              @click="orderLoaded(order.id)"
              class="is-fullwidth" style="height: auto">
            Naložil jsem celou objednávku
          </b-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// položka objednávky
import OrderItemHandOver from "./OrderItemHandOver";

export default {
  name: "Modal",
  components: {
    OrderItemHandOver,  // položka
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  created() {
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    closeModal() {
      alert('aaa')
      this.close()
    },
    orderLoaded(orderId) {
      this.$store.dispatch('setOrderLoaded', {
        orderId: orderId,
        key: this.order.key
      })
          .then(response => {
            console.log(response)
            // this.parent.$emit('loadTransport')
            this.$router.push({name: 'redirect', params: {where: 'pickup', orderId: orderId, key: this.order.key}})
            this.$emit('close')
          })
          .catch(error => {
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            if (error.response.data !== undefined) {
              this.errorApiCode = error.response.data.error.code
              this.errorApiMessage = error.response.data.error.message
            }
            this.successMessage = ''

            console.table(error)
          })
    },
  }
}
</script>

<style lang="scss">

</style>