<template>
  <div id="app">
    <div v-if="showHeader" class="header navbar">
      <div class="logo">
        <router-link :to="{ name: 'home' }">
          <img src="./../../assets/img/logo.svg" title="BellesFleurs.cz" alt="BellesFleurs.cz">
        </router-link>
      </div>
      <div class="description">
        <div v-if="!loggedIn" class="app">
          {{ appDescription }}
        </div>
        <div v-else-if="loggedIn && transportInfo !== null" class="transport-info">
          <div v-if="!transportInfo.isFinishedAll" class="item finished">
            <router-link :to="{ name: 'transport-transfer', params: {mode: 'transfer'} }" title="Předávka">
              <i class="fal fa-truck"></i> {{transportInfo.countFinished}}/{{transportInfo.countOrders}}
            </router-link>
          </div>
          <div v-if="!transportInfo.isFinishedAll" class="item navigation">
            <a :href="transportInfo.navigationLink" target="navigations" title="Navigace">
              <i class="fal fa-location"></i>
            </a>
          </div>
          <div v-if="!transportInfo.isLoadedAll" class="item loaded">
            <router-link :to="{ name: 'transport-pickUp', params: {mode: 'pickUp'} }" title="Nakládka">
              <i class="fal fa-qrcode"></i> {{transportInfo.countLoaded}}/{{transportInfo.countOrders}}
            </router-link>
          </div>
        </div>
      </div>
      <div class="hamburger">
        <b-dropdown aria-role="menu" append-to-body position="is-bottom-left">
          <button class="button" slot="trigger" slot-scope="{ active }">
            <b-icon :icon="active ? 'times' : 'bars'"></b-icon>
          </button>
<!-- položky          -->
          <b-dropdown-item has-link v-if="loggedIn" aria-role="menuitem">
            <router-link :to="{ name: 'home' }" class="dropdown-item">Domů</router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link v-if="loggedIn && isTransportLoaded" aria-role="menuitem">
            <router-link :to="{ name: 'transport-cancel' }" class="dropdown-item">Přerušit závoz</router-link>
          </b-dropdown-item>
          <hr v-if="loggedIn" class="dropdown-divider" aria-role="menuitem">
          <b-dropdown-item has-link type="router-link" v-if="!loggedIn" aria-role="menuitem">
            <router-link :to="{ name: 'login' }" class="dropdown-item">Login</router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link v-if="loggedIn" aria-role="menuitem">
            <router-link :to="{ name: 'logout' }" class="dropdown-item">Logout</router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="container is-fluid">
      <router-view :key="$route.path"></router-view>
    </div>

    <div class="footer">
      <div class="description">
        <p>&copy; 2020 - 2021 Via Aurea s.r.o.</p>
      </div>
      <div class="internal-info">
        <div class="item">
          <span>
            Api: {{ apiEndPoint }}
          </span>
          <span>
            Version {{ gitVersion }}
          </span>
          <span>
            Dist {{ gitDistance }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Master",
  data() {
    return {
      showHeader: this.$store.state.visibleHeader,
      appDescription: "Závozy pro BellesFleurs.cz",
      apiEndPoint: process.env.VUE_APP_API_BASE_URL,
      gitVersion: 'v0.9.3',//GIT_DESCRIBE.raw,
      gitDistance: 0,//GIT_DESCRIBE.distance,
    }
  },
  created() {
    this.retrieveTransportInfo()
  },
  components: {

  },
  computed: {
    // showHeader: this.$store.state.visibleHeader
    loggedIn() {
      return this.$store.getters.loggedIn
    },
    transportInfo() {
      return this.$store.getters.getTransportInfo
    },
    isTransportLoaded() {
      return this.$store.getters.isTransportLoaded
    },
  },
  methods: {
    disableHeader() {
      this.showHeader = false;
    },
    retrieveTransportInfo() {
      if (this.loggedIn && this.transportInfo === null) {
        let transportKeys = this.$store.getters.getTransportKeys//JSON.parse(localStorage.getItem('transport'))
        this.$store.dispatch('retrieveTransportInfo', transportKeys)
        // .then(response => {
        //   console.log(response)
        // })
        // .catch(error => {
        //   console.table(error)
        // })
      }
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
  }
}
</script>

<style lang="scss">
  @import "./../../assets/css/style";
</style>