/** env - https://www.npmjs.com/package/dotenv */
require('dotenv').config({path: '/.env'});

/** Vue app */
import Vue from 'vue'

import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
if (process.env.VUE_APP_SENTRY == "1") {
  /** Sentry */
  Sentry.init({
    dsn: "https://f08642db06ca41f0ace98b142ce47da7@o71591.ingest.sentry.io/5539051",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

/** router */
import VueRouter from "vue-router";
import routes from "./routes";
/** uložiště */
import { store } from "./store/store";
/** Buefy komponenty */
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
/** hlavní layout */
import Master from "./components/layouts/Master";

/** rest */
const axios = require('axios').default;
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.use(Buefy, {
  defaultIconPack: 'fal',
});

// router - nastaveni
Vue.use(VueRouter)
const router = new VueRouter({
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})


// app
new Vue({
  el: '#app',
  router: router,
  store: store,
  components: {
    Master
  },
  template: '<Master/>',
  render: h => h(Master)
})
