import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

export const store = new Vuex.Store({
    state: {
        token: localStorage.getItem('access_token') || null,
        transportKeys: JSON.parse(localStorage.getItem('transport')) || [],
        info: {},
        visibleHeader: true,
        test: true,
        transportInfo: null,
        transports: [],
        // token: localStorage.getItem('access_token') || null,
        // filter: 'all',
        // todos: [],
    },
    getters: {
        order(state, orderId) {
            return orderId
        },
        loggedIn(state) {
            return state.token !== null
        },
        getTransportInfo(state) {
           return state.transportInfo
        },
        getTransportKeys(state) {
            console.log(state.transportKeys.length > 0, 'klice - data')
            return state.transportKeys
        },
        isTransportLoaded(state) {
            let keys = state.transportKeys
            // console.log(Array.isArray(keys));
            // console.log(keys.length > 0)
            return Array.isArray(keys) && keys.length > 0
        }
    },
    mutations: {
        // auth
        retrieveToken(state, token) {
            state.token = token
        },
        destroyToken(state) {
            state.token = null
        },
        // uloží klíče přepravy
        setTransport(state, key) {
            if (localStorage) {
                var transport;
                if (!localStorage['transport']) {
                    transport = []
                } else {
                    transport = JSON.parse(localStorage['transport'])
                }
                if (!(transport instanceof Array)) {
                    transport = []
                }
                if (transport.indexOf(key) === -1) {
                    transport.push(key);
                }
                localStorage.setItem('transport', JSON.stringify(transport));
            }
            state.transportKeys = JSON.stringify(transport)
        },
        destroyTransport(state) {
            state.transports = []
        },
        retrieveTransport(state, transports) {
            state.transports = transports;
        },
        cancelTransport(state) {
            state.transportKeys = []
            state.transportInfo = null
        },
        setOrderNotDelivery(state, data) {
            console.log(data)
        },
        setOrderHandOver(state, data) {
            console.log(data)
        },
        setOrderPickedUp(state, data) {
            console.log(data)
        },
        setOrderFinished(state) {   // dokončeno
            state.transportKeys = []
        },
        setOrderLoaded(state, data) {
            console.log(data)
        },
        checkOrder(state, data) {
            console.log(data)
        },
        isLoaded(state, data) {
            console.log(data)
        },
        retrieveTransportInfo(state, data) {
            state.transportInfo = data
        },
    },
    actions: {
        // auth
        destroyToken(context) { // todo api
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token

            if (context.getters.loggedIn) {
                context.commit('destroyToken')
                localStorage.removeItem('access_token')
/*                return new Promise((resolve, reject) => {
                    axios.post('/logout')
                        .then(response => {
                            localStorage.removeItem('access_token')
                            context.commit('destroyToken')
                            resolve(response)
                            // console.log(response);
                            // context.commit('addTodo', response.data)
                        })
                        .catch(error => {
                            localStorage.removeItem('access_token')
                            context.commit('destroyToken')
                            reject(error)
                        })
                })*/
            }
        },
        retrieveToken(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('/login', {
                    username: credentials.username,
                    password: credentials.password,
                })
                .then(response => {
                    const token = response.data.data.access_token
                    localStorage.setItem('access_token', token)
                    console.log('krok1');
                    console.log(response);

                    return response
                })
                .then(response => {
                    const token = response.data.data.access_token
                    context.commit('retrieveToken', token)
                    console.log('krok2');
                    console.log(response);
                    resolve(response)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        },
        // app
        checkTransport(context, key) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            return new Promise((resolve, reject) => {
                // axios.defaults.headers.common['origin'] = 'localhost:8080'
                axios.get('/transport/check', {
                    params: {
                        key: key
                    }
                })
                    .then(response => {
                        const key = response.data.data.key
                        context.commit('setTransport', key)
                        resolve(response)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        async retrieveTransport(context, keys) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            return await new Promise((resolve, reject) => {
                axios.get('/transport/get', {
                    params: {
                        key: keys//context.state.transportKeys
                    }
                })
                    .then(response => {
                        context.commit('retrieveTransport', response.data.data)
                        context.dispatch('retrieveTransportInfo', keys)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        cancelTransport(context) {
            return new Promise((resolve/*, reject*/) => {
                localStorage.removeItem('transport')

                context.commit('cancelTransport')
                resolve()
            })
        },
        async retrieveTransportInfo(context, keys) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            return await new Promise((resolve, reject) => {
                axios.get('/transport/getTransportInfo', {
                    params: {
                        key: keys//context.state.transportKeys
                    }
                })
                    .then(response => {
                        context.commit('retrieveTransportInfo', response.data.data)
                        console.log(response.data, 'data')
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        finishTransport(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            // todo oznacit za konec
            context.commit('destroyTransport')
            localStorage.removeItem('transport')
        },
        setOrderNotDelivery(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            return new Promise((resolve, reject) => {
                axios.get('/transport/order/notfound', {
                    params: {
                        id: payload.id,
                        key: payload.key,
                        itemId: payload.itemId,
                    }
                })
                    .then(response => {
                        context.commit('setOrderNotDelivery', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        checkOrder(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            // axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.get('/transport/order/check', {
                    params: {
                        id: payload.orderId,
                        key: payload.key,
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        context.commit('checkOrder', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        async isLoadedAll(context, keys) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            // axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.get('/transport/isLoaded', {
                    params: {
                        key: keys//context.state.transportKeys//payload.key,
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        context.commit('isLoaded', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        setOrderLoaded(context, payload) {
            console.log(payload)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.patch('/transport/order/loaded/' + payload.orderId, {
                    params: {
                        key: payload.key
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        context.commit('setOrderLoaded', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        setOrderHandOver(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.patch('/transport/order/handover/' + payload.id, {
                    params: {
                        data: payload.data,
                        id: payload.id,
                        key: payload.key,
                        itemId: payload.itemId,
                        note: payload.note
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        context.commit('setOrderHandOver', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        setOrderPickedUp(context, payload) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.patch('/transport/pickedUp', {
                    params: {
                        key: context.state.transportKeys,
                        notification: payload.notification,
                        sms: true,
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        context.commit('setOrderPickedUp', response.data)
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        setOrderFinished(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
            return new Promise((resolve, reject) => {
                axios.patch('/transport/finished', {
                    params: {
                        key: context.state.transportKeys,
                    },
                    headers: {
                        // 'Content-Type': 'application/x-www-form-urlencoded', // neodkomentovavat!!!
                    },
                })
                    .then(response => {
                        localStorage.removeItem('transport')
                        context.commit('setOrderFinished')
                        resolve(response.data)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        retrieveInfo(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
            return new Promise((resolve, reject) => {
                axios.get('/info')
                    .then(response => {
                        context.commit('retrieveInfo', response.data)
                        resolve(response)   // propise "dolu"!!!
                    })
                    .catch(error => {
                        console.log(error)
                        reject(error)
                    })
            })
        },
        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        },
    }
});