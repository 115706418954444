<template>
  <div class="order-item" :class="mode">
    <b-collapse
        class="order-item card" :class="mode"
        animation="slide"
        :key="order.id"
        :open="mode == 'handOver'"
        @open="isOpen = order.id">
      <div
          slot="trigger"
          class="card-header"
          role="button"
          v-bind:class="{
            'delivered': mode === 'transfer' && order.status.isDelivered,
            'not-found': mode === 'transfer' && order.status.isNotFound,
            'loaded': mode === 'pickUp' && order.status.isLoaded,
        }">
        <div class="identifier">
          {{ order.showId }}
        </div>
        <div v-if="mode === 'pickUp'" class="count" :class="order.countItems === 1 ? 'image' : ''">
          <span v-if="order.countItems > 1">
            {{order.countItems}}
          </span>
          <img v-else-if="order.image" v-bind:src="order.image" />
          <i v-else class="fal fa-flower"></i>
        </div>
        <div class="info">
          <span class="address">
            {{order.address.street}}<br />{{order.address.city}} {{order.address.zip}}
          </span>
          <span class="delivery-info">
            <span v-if="order.icons.onTime" class="time">
              <i class="fal fa-shipping-timed"></i> {{order.delivery.from}} &mdash; {{order.delivery.to}}
            </span>
            <span v-else="" class="time-ok">
              {{order.delivery.from}} &mdash; {{order.delivery.to}}
            </span>
            <span v-if="order.icons.note" class="note">
              <i class="fal fa-comment-alt-dots"></i>
            </span>
            <span v-if="order.icons.address" class="problem-address">
              <i class="fal fa-exclamation-triangle"></i> 10min+
            </span>
            <span v-if="order.icons.branch" class="branch">
              <i class="fal fa-store"></i> Pobočka
            </span>
            <span v-if="order.icons.adult" class="adult">
              <i class="fal fa-wine-bottle"></i> 18+
            </span>
          </span>
        </div>
        <div class="icons">
          <div v-if="mode === 'transfer'" class="buttons">
            <b-button v-if="!order.status.isFinished && mode != 'handOver'"
                      tag="button"
                      @click="openOrderDetail(order)"
                      :title="order.status.isDelivered ? 'Již doručeno' : ''"
                      icon-right="hand-holding-box"/>
            <b-button v-if="!order.status.isFinished"
                      tag="a"
                      title="Google navigace"
                      v-bind:href="order.delivery.googleMapsLink"
                      target="_blank"
                      icon-right="location"/>
            <b-button tag="a"
                      title="Volat"
                      v-bind:href="order.phoneLink"
                      target="_blank"
                      icon-right="phone"/>
          </div>
          <div v-if="mode === 'pickUp'" class="info-icons">
            <div class="shipping">
              <i v-if="order.icons.onTime" class="fal fa-shipping-timed" title="Objednávka na čas"></i>
            </div>
            <div class="address">
              <i v-if="order.icons.address" title="Při předávce na této adrese se můžete zdržet" class="fal fa-exclamation-triangle"></i>
            </div>
            <div class="cod">
              <i v-if="order.icons.cod" title="Dobírka" class="fal fa-hand-holding-usd"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="info">
          <span class="delivery">
            <i class="fal fa-truck" title="Objednávka na čas"></i> {{order.delivery.tariffName}} {{order.delivery.from}} &mdash; {{order.delivery.to}}
          </span>
          <span class="else">
            <i v-if="order.icons.address" title="Při předávce na této adrese se můžete zdržet" class="fal fa-exclamation-triangle"></i>
            <i v-if="order.icons.adult" title="Zboží pro dospělé" class="fal fa-wine-bottle"></i>
            <i v-if="order.icons.cod" title="Dobírka" class="fal fa-hand-holding-usd"></i>
          </span>
        </div>
        <div class="content">
          <div class="address">
            <span v-if="order.address.firm" class="firm">{{order.address.firm}}</span>
            <span>{{order.address.firstname}} &nbsp; {{order.address.lastname}}</span>
            <span>{{order.address.street}}<br />{{order.address.city}} {{order.address.zip}}</span>
            <span>{{order.phone}}</span>
          </div>
          <div v-if="order.note" class="note">
            <p>{{order.note}}</p>
          </div>
          <div class="order-items">
            <div v-for="(item, index) in order.items" v-bind:key="item.id" class="item">
              <div class="image" :class="index">
                <img v-if="item.product.image" v-bind:src="item.product.image" />
                <i v-else class="fal fa-flower"></i>
              </div>
              <div class="count">
                <span v-if="item.count > 1" class="num">{{item.count}}</span> <span v-if="item.count > 1">ks</span>
              </div>
              <div class="name">
                {{item.product.title}}
              </div>
            </div>
          </div>
          <div v-if="mode == 'pickUp' && !order.status.isLoaded" class="buttons order-handover">
            <b-button
                tag="a"
                @click="pickUpOrder(order.id)"
                class="order-load" style="height: auto">
              Naložil jsem objednávku
            </b-button>
          </div>
          <div v-if="mode == 'handOver'" class="order-handover">
            <div class="buttons">
              <b-button
                  tag="a"
                  @click="openHandOver(order, 'recipient')"
                  class="is-fullwidth" style="height: auto">
                Zásilku přebírá <br/> {{order.address.firstname}} {{order.address.lastname}}
              </b-button>
              <b-button
                  tag="button"
                  @click="openHandOver(order, 'else')"
                  class="is-fullwidth">
                Přebírá někdo jiný
              </b-button>
              <b-button
                  v-if="showButtonNotFound"
                  tag="a"
                  class="is-fullwidth"
                  @click="showNotFoundInfo">
                Nezastižen
              </b-button>
              <div v-if="showInfoNotFound" class="info-not-found">
                <p>Adresáta nelze kontaktovat? <strong>Zkuste zavolat ještě kupujícímu.</strong></p>
                <div class="buttons-inner">
                  <b-button tag="a"
                            title="Volat kupujícímu"
                            class="buyer-phone"
                            v-bind:href="order.phoneBuyerLink"
                            target="_blank"
                            icon-right="phone"/>
                  <b-button
                      tag="a"
                      class="not-found-button"
                      @click="handOverNotFound">
                    Nelze předat
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <div class="api-error">
      <article v-if="errorApi" class="message is-danger is-small">
        <div class="message-header">
          <p>Chyba</p>
        </div>
        <div class="message-body">
          <p>
            <strong>{{ errorApiCode }}:</strong> {{ errorApiMessage }}</p>
        </div>
      </article>
    </div>

  </div>
</template>

<script>
// detail objednávky - popup (mode: handOver)
import OrderDetail from "./OrderDetail";
// předávka -
import OrderHandOver from "./OrderHandOver";

export default {
  name: "OrderItemHandOver",
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      isOpen: this.mode != 'handOver' ? true : false,
      showButtonNotFound: true, // zobraz hlavní button
      showInfoNotFound: false,  // zobraz informace
      //
      id: this.order.id,
      items: this.order.items,
      //
      loading: false,
      errorApi: false,
      errorApiCode: null,
      errorApiMessage: null,
    }
  },
  created() {

  },
  methods: {
    test() {
      return this.order
    },
    // detail objednávky - popup
    openOrderDetail(order) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: OrderDetail,
        customClass: '',
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        props: {
          order: order,
          mode: 'handOver',
        }
      })
    },
    // otevření předávky
    openHandOver(order, mode) {
      this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: OrderHandOver,
        customClass: '',
        trapFocus: true,
        fullScreen: true,
        canCancel: false,
        props: {
          order: order,
          mode: mode,
        }
      })
    },
    // nezastižení odběratele
    handOverNotFound() {
      this.$buefy.dialog.confirm({
        title: 'Nezastižen',
        message: 'Zásilku označíte za nedoručitelnou',
        confirmText: 'Ok',
        cancelText: 'Zrušit',
        type: 'is-warning',
        hasIcon: false,
        onConfirm: () => {
          this.$store.dispatch('setOrderNotDelivery', {
            id: this.order.id,
            key: this.order.key,
            itemId: this.order.inhouseItemId,
          })
              .then(response => {
                console.log(response)
                this.$buefy.dialog.alert({
                  message: response.data.message,
                  onConfirm: () => {
                    this.$router.push({name: 'redirect', params: {where: 'transfer', key: this.order.key}})
                    this.$parent.$emit('close') // todo jak zavřít ten modal??
                  }
                })
              })
              .catch(error => {
                this.loading = false
                // this.serverError = error.response.data
                this.result = ''
                this.errorApi = true
                this.errorApiCode = error
                this.errorApiMessage = error
                this.successMessage = ''
              })
          this.loading = false
        }
      })
    },
    pickUpOrder(orderId) {
      console.log(orderId)
      console.log(this.order.key)
      this.$store.dispatch('setOrderLoaded', {
        orderId: orderId,
        key: this.order.key
      })
          .then(response => {
            console.log(response)
            // this.$emit('isLoadedAll')
            // this.$emit('loadTransport')
            this.$router.push({name: 'redirect', params: {where: 'pickup', orderId: orderId, key: this.order.key}})
          })
          .catch(error => {
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            if (error.response.data !== undefined) {
              this.errorApiCode = error.response.data.error.code
              this.errorApiMessage = error.response.data.error.message
            }
            this.successMessage = ''
            console.table(error)
          })
      this.loading = false
    },
    showNotFoundInfo() {
      this.showButtonNotFound = false;
      this.showInfoNotFound = true;
    },
  }
}
</script>

<style lang="scss">

</style>