<template>
  <div class="page-wrapper finished">
    <h2>Ukončení závozu</h2>
    <div v-if="transportInfo !== null" class="table-container">
      <table class="summary table">
        <tr>
          <td>Objednávek</td>
          <td>{{transportInfo.countOrders}}</td>
        </tr>
        <tr>
          <td>Naložených objednávek</td>
          <td>{{transportInfo.countLoaded}}</td>
        </tr>
        <tr>
          <td>Odevzdaných objednávek</td>
          <td>{{transportInfo.countFinished}}</td>
        </tr>
      </table>
    </div>

    <div class="buttons">
      <b-button
          type="button"
          @click="finishTransport">
        Ukončit závoz
      </b-button>
    </div>

  </div>
</template>
<script>
export default {
  name: "FinishedTransport",
  created() {
    let transportKeys = JSON.parse(localStorage.getItem('transport'))
    this.$store.dispatch('retrieveTransportInfo', transportKeys)
  },
  computed: {
    transportInfo() {
      return this.$store.getters.getTransportInfo
    },
  },
  methods: {
    finishTransport() {
      this.$store.dispatch('setOrderFinished')
          .then(response => {
            console.log(response)
            this.$router.push({name: 'home'})
          })
          .catch(error => {
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            if (error.response.data !== undefined) {
              this.errorApiCode = error.response.data.error.code
              this.errorApiMessage = error.response.data.error.message
            }
            this.successMessage = ''
            console.table(error)
          })
    }
  }
}
</script>

<style scoped>

</style>