<template>
  <div class="page-wrapper login">
    <div class="logo">
      <img src="./../../assets/img/logo.svg" title="BellesFleurs.cz" alt="BellesFleurs.cz">
    </div>
    <section class="hero login-form">
      <div class="hero-body">
        <span class="truck">
          <i class="fal fa-truck-moving"></i>
        </span>
        <div class="container">
          <form action="#" @submit.prevent="login">
            <b-field label="Přihlašovací jméno"
                     type=""
                     message="">
              <b-input value=""
                       placeholder="Přihlašovací jméno"
                       v-model="username"
                       type=""
                       required>

              </b-input>
            </b-field>
            <b-field label="Heslo">
              <b-input type="password"
                       placeholder="Heslo"
                       value="iwantmytreasure"
                       v-model="password"
                       required>
              </b-input>
            </b-field>
            <div class="buttons">
              <b-button type="is-primary"
                        tag="input"
                        native-type="submit" value="Přihlásit se"
                        :disabled="loading"
                        :loading="loading">
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      usernameError: '',
      message: null,
      loading: false,
    }
  },
  created() {
    this.$store.state.visibleHeader = false;
  },
  computed: {

  },
  methods: {
    async login() {
      this.loading = true
      this.$store.dispatch('retrieveToken', {
        username: this.username,
        password: this.password,
      })
      .then(response => {
        this.message = response.data.message
        this.loading = false
        this.$router.push({ name: 'home' })
      })
      .catch(error => {
        this.loading = false
        this.$buefy.dialog.alert({
          title: 'Error',
          message: error.response.data.error.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        console.log(error.response.data)
        // error jako param
        // this.serverError = error.response.data
        // this.password = ''
        // this.successMessage = ''
      })
    }
  }
}
</script>

<style scoped>

</style>