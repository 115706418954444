<template>
  <div class="page-wrapper cancel">
    <b-message type="is-info" has-icon>
      Potřebujete-li načíst nové závozy nebo nejsou dokončeny předchozí, tak kliknitím na tlačítko závozy přerušíte. Ty lze zase načíst a dokončit.
    </b-message>

    <div class="buttons">
      <b-button tag="button"
                @click="cancelTransport"
                title="Zruší Vámi načtený závoz"
                icon-left="truck">
        Přerušit načtené závozy
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CancelTransport",
  data() {
    return {
      closable: false,
    }
  },
  methods: {
    cancelTransport() {
      this.$store.dispatch('cancelTransport')
          .then(response => {
            response;
            this.$router.push({name: 'home'})
          })
          .catch(error => {
            // this.loading = false
            // // this.serverError = error.response.data
            // this.result = ''
            // this.errorApi = true
            // if (error.response.data !== undefined) {
            //   this.errorApiCode = error.response.data.error.code
            //   this.errorApiMessage = error.response.data.error.message
            // }
            // this.successMessage = ''
            console.table(error)
          })
    }
  }
}
</script>

<style scoped>

</style>