<template>
  <div class="page-wrapper read-transport qr">
    <div class="reader-qr">
      <qrcode-stream @decode="onDecode" @init="onInit">
        <b-loading :is-full-page="isFullPage" v-model="isLoadingCamera" :can-cancel="false">
          <b-icon
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
        </b-loading>
      </qrcode-stream>
    </div>
    <div class="reader-input">
      <form @submit="readTransport">
        <b-field label="Kód závozu">
          <b-input
              v-model="result"
              placeholder="Zadejte kód závozu nebo ho naskenujte"
              required
          ></b-input>
        </b-field>
        <div class="buttons">
          <b-button type="is-primary"
                    tag="input"
                    native-type="submit" value="Přidat"
                    :disabled="loading"
                    :loading="loading">
          </b-button>
        </div>
      </form>
      <div v-if="isTransportLoaded" class="transport-loaded">
        <p>Načtené závozy:</p>
        <div class="items">
          <div v-for="(transport, index) in transportKeys" v-bind:key="index">
            {{transport}}
          </div>
        </div>
      </div>
    </div>
    <div class="api-error">
      <article v-if="errorApi" class="message is-danger is-small">
        <div class="message-header">
          <p>Chyba</p>
        </div>
        <div class="message-body">
          <p>
            <strong>{{ errorApiCode }}:</strong> {{ errorApiMessage }}</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: "ReadTransport",
  components: {QrcodeStream},
  data () {
    return {
      isValid: undefined,
      camera: 'rear',
      result: '',
      loading: false,
      errorApi: false,
      errorApiCode: null,
      errorApiMessage: null,
      isLoadingCamera: false,
      isFullPage: false,
      destroyed: false,
    }
  },
  mounted() {

  },
  created() {
    // localStorage.removeItem('transport')
  },
  computed: {
    validationPending () {
      return this.isValid === undefined
          && this.camera === 'off'
    },
    validationSuccess () {
      return this.isValid === true
    },
    validationFailure () {
      return this.isValid === false
    },
    isTransportLoaded() {
      return this.$store.getters.isTransportLoaded
    },
    transportKeys() {
      return this.$store.state.transportKeys
    }
  },

  methods: {
    onInit (promise) {
      this.isLoadingCamera = true
      this.turnCameraOff()
      promise
          .catch(console.error)
          .then(this.resetValidationState)
    },
    resetValidationState () {
      this.isValid = undefined
      this.isLoadingCamera = false
    },
    async onDecode (content) {
      // this.loading = true
      this.result = content
      if (content !== '') {
        this.playBeep()
      }
      this.turnCameraOff()
      // pretend it's taking really long
      await this.timeout(3000)
      this.isValid = content.startsWith('http')
      // some more delay, so users have time to read the message
      await this.timeout(2000)
      this.turnCameraOn()
    },
    turnCameraOn () {
      this.camera = 'rear'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    // zvuk
    playBeep() {
      var audio = new Audio(require('./../../assets/sounds/beep01.mp3'));
      audio.play();
    },
    // transports
    readTransport(e) {
      e.preventDefault()
      this.loading = true;
      this.$store.dispatch('checkTransport', this.result)
          .then(response => {
            console.log(response)
            this.errorApi = false
            this.errorApiCode = null
            this.errorApiMessage = null
            this.$router.push({ name: 'transport-pickUp', params: { key: response.data.data.key, mode: 'pickUp'} })
          })
          .catch(error => {
            this.resetValidationState()
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            if (error.response.data !== undefined) {
              this.errorApiCode = error.response.data.error.code
              this.errorApiMessage = error.response.data.error.message
            }
            this.successMessage = ''
            console.table(error);
          })
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>