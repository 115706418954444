<template>
  <div class="page-wrapper" :class="mode">
    <!-- Čtečka -->
    <div v-if="mode === 'pickUp' && !loadedAll" class="read-order qr">
      <qrcode-stream @decode="onDecode" @init="onInit">
        <b-loading :is-full-page="isFullPage" v-model="isLoadingCamera" :can-cancel="false">
          <b-icon
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin">
          </b-icon>
        </b-loading>
      </qrcode-stream>
    </div>
    <!-- Odeslání SMS -->
    <div v-if="mode === 'pickUp' && loadedAll" class="pickup-info">
      <span>Načteny všechny zásilky</span>
      <div class="buttons">
        <b-button tag="button"
                  @click="start(true)"
                  class="is-fullwidth main">
            Start + odeslat SMS
        </b-button>
        <b-button tag="button"
                  @click="start(false)"
                  class="is-fullwidth">
          Start bez SMS
        </b-button>
      </div>
    </div>
    <!--  orders  -->
    <div class="transport" v-for="transport in transports" :key="transport.key" :class="transport.key">
      <order-item v-for="(order) in transport.orders"
                  :order="order"
                  :mode="mode"
                  :transport-key="transport.key"
                  v-bind:key="order.id"></order-item>
    </div>
    <!-- Chyby -->
    <div class="api-error">
      <article v-if="errorApi" class="message is-danger is-small">
        <div class="message-header">
          <p>Chyba</p>
        </div>
        <div class="message-body">
          <p>
            <strong>{{ errorApiCode }}:</strong> {{ errorApiMessage }}</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import OrderItem from "./order/OrderItem";
import { QrcodeStream } from 'vue-qrcode-reader'
import OrderDetail from "./order/OrderDetail";

export default {
  name: "PickUpTransport",
  components: {
    OrderItem,
    QrcodeStream
  },
  data() {
    return {
      //
      key: this.$route.params.key,
      // transportKeys: [],
      mode: this.$route.params.mode,
      //
      isValid: undefined,
      camera: 'rear',
      result: '1i',
      isLoadingCamera: false,
      isFullPage: false,
      //
      transports: [],
      loadedAll: false,
      loading: false,
      errorApi: false,
      errorApiCode: null,
      errorApiMessage: null,
    }
  },
  computed: {
    validationPending () {
      return this.isValid === undefined
          && this.camera === 'off'
    },
    validationSuccess () {
      return this.isValid === true
    },
    validationFailure () {
      return this.isValid === false
    },
  },
  created() {
    this.loadTransport()
    this.isLoadedAll()
    console.log('volam?')
    this.checkFinishedTransport()
  },
  methods: {
    //
    onInit (promise) {
      this.isLoadingCamera = true
      this.turnCameraOff()
      promise
          .catch(console.error)
          .then(this.resetValidationState)
    },
    resetValidationState () {
      this.isValid = undefined
      this.isLoadingCamera = false
    },
    async onDecode (content) {
      // this.loading = true
      this.result = content
      if (content !== '') {
        this.playBeep()
      }
      await this.timeout(1000)
      this.setLoadedOrder(content)

      this.turnCameraOff()
      // pretend it's taking really long
      await this.timeout(3000)
      // some more delay, so users have time to read the message
      await this.timeout(2000)
      this.turnCameraOn()
    },
    turnCameraOn () {
      this.camera = 'rear'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    // zvuk
    playBeep() {
      var audio = new Audio(require('./../../assets/sounds/beep01.mp3'));
      audio.play();
    },
    //
    // načtení doprav
    async loadTransport() {
      this.loading = true;
      let transportKeys = JSON.parse(localStorage.getItem('transport'))
      this.$store.dispatch('retrieveTransport', transportKeys)
          .then(response => {
            this.transports = response.data;
            this.$store.dispatch('retrieveTransportInfo', transportKeys)
            console.table(response.data)
          })
          .catch(error => {
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            if (error.response.data !== undefined) {
              this.errorApiCode = error.response.data.error.code
              this.errorApiMessage = error.response.data.error.message
            }
            this.successMessage = ''
            console.table(error)
          })
      this.loading = false
    },
    // označení nakládky
    setLoadedOrder(orderId) {
      // počet objednávek // /transport/order/check
      this.$store.dispatch('checkOrder', {
            orderId: orderId,
            key: this.key
          })
          .then(response => {
            // více položek
            if (response.data.countItems > 1) {
              // načtení ze závozu
              let order = this.getOrder(orderId)
              if (order === null) {
                alert('Objednávka má více položek, ale nepodařilo se nám ji načíst')
              } else {
                this.$buefy.modal.open({
                  parent: this,
                  hasModalCard: true,
                  component: OrderDetail,
                  customClass: '',
                  trapFocus: true,
                  fullScreen: true,
                  canCancel: false,
                  props: {
                    order: order,
                    mode: 'pickUp',
                  }
                })
              }
              // this.loadTransport()
            } else if (response.data.countItems == 1) {
              this.internalSetOrderLoaded(orderId)
            } else if (orderId !== '') {
              alert('Neexistuje objednávka s id ' + orderId)
            }
          })
          .catch(error => {
            this.loading = false
            // this.serverError = error.response.data
            this.result = ''
            this.errorApi = true
            // if (error.response.data !== undefined) {
            //   this.errorApiCode = error.response.data.error.code
            //   this.errorApiMessage = error.response.data.error.message
            // }
            // this.successMessage = ''

            console.table(error)
          })
      this.loading = false
    },
    isLoadedAll() {
      let transportKeys = JSON.parse(localStorage.getItem('transport'))
      console.log(transportKeys)
      this.$store.dispatch('isLoadedAll', transportKeys)
      .then(response => {
        this.loadedAll = response.data.isLoadedAll
        // this.loadTransport()
      })
      .catch(error => {
        this.loading = false
        // this.serverError = error.response.data
        this.result = ''
        this.errorApi = true
        if (error.response.data !== undefined) {
          this.errorApiCode = error.response.data.error.code
          this.errorApiMessage = error.response.data.error.message
        }
        this.successMessage = ''

        console.table(error)
      })
      this.loading = false
    },
    internalSetOrderLoaded(orderId) {
      console.log(orderId)
      this.$store.dispatch('setOrderLoaded', {
        orderId: orderId,
        key: this.key
      })
      .then(response => {
        console.log(response)
        this.isLoadedAll()
        this.loadTransport()
      })
      .catch(error => {
        this.loading = false
        // this.serverError = error.response.data
        this.result = ''
        this.errorApi = true
        if (error.response.data !== undefined) {
          this.errorApiCode = error.response.data.error.code
          this.errorApiMessage = error.response.data.error.message
        }
        this.successMessage = ''

        console.table(error)
      })
      this.loading = false
    },

    getOrder(orderId) {
      console.log(parseInt(orderId))
      let returnOrder = null
      let t = Object.values(this.transports)
      t.forEach(function (item, index) {  // todo klic prepravy
        console.log(index)
        let o = Object.values(item.orders)
        o.forEach(function (order) {
          if (parseInt(order.id) === parseInt(orderId)) { // todo
            returnOrder = order
          }
        })
      })
      return returnOrder;
    },

    start(withNotify) {  // SMS a pak po šoupnout
      this.$store.dispatch('setOrderPickedUp', {
        key: this.key,
        notification: withNotify
      })
      .then(response => {
        console.log(response)
        this.$router.push({name: 'redirect', params: {where: 'transfer', key: this.key}})
      })
      .catch(error => {
        this.loading = false
        // this.serverError = error.response.data
        this.result = ''
        this.errorApi = true
        if (error.response.data !== undefined) {
          this.errorApiCode = error.response.data.error.code
          this.errorApiMessage = error.response.data.error.message
        }
        this.successMessage = ''
        console.table(error)
      })
    },
    // start() { // bez SMS - jdeme dále
    //   this.$router.push({name: 'redirect', params: {where: 'transfer', key: this.key}})
    // },
    checkFinishedTransport() {
      // let keys = this.$store.getters.getTransportKeys
      console.log(this.$store.getters.getTransportKeys.length > 0, 'klice - klice')
      console.log(this.$store.getters.getTransportKeys)
      let keys = [];
      if (Array.isArray(this.$store.getters.getTransportKeys)) {
        keys = this.$store.getters.getTransportKeys
      } else {
        keys = JSON.parse(this.$store.getters.getTransportKeys)
      }
      this.$store.dispatch('retrieveTransportInfo', keys)
      .then(response => {
        let transportInfo = response.data
        console.log(keys, 'keys')
        console.log(transportInfo, 'tutu')
        console.log(transportInfo !== null)
        console.log(transportInfo.isFinishedAll)
        if (transportInfo !== null && transportInfo.isFinishedAll) {
          // console.log('vůůůůůůůůůůůůůůůůůůl')
          this.$router.push({ name: 'transport-finish' })
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>